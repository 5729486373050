











import ProductsHelper from '@/views/shop/ProductsHelper'
import CashRegisterHelper from './CashRegisterHelper'
import { ShopService } from '@/includes/services/ShopService'
import CSVExportService from '@/includes/services/CSVExportService'
import { errorNotification } from "@/includes/services/NotificationService";

import Shop from 'piramis-base-components/src/components/Shop/Shop.vue'
import { ShopConfig } from 'piramis-base-components/src/components/Shop/types'
import Currencies from 'piramis-base-components/src/components/Shop/Currencies'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import FileApi from 'piramis-base-components/src/components/File/logic/file-api'
import { NoticeState } from "piramis-base-components/src/components/Shop/Notices/types";

import { cloneDeep } from 'lodash'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    PageTitle,
    Shop,
  }
})
export default class NewShop extends Vue {
  config: ShopConfig | null = null

  async created(): Promise<void> {
    if (!this.$store.state.shopState.cashRegisters) {
      await this.$store.dispatch('getCashRegisters')
    }
    if (!this.$store.state.shopState.products) {
      await this.$store.dispatch('getProducts')
    }

    const fileApi = new FileApi()

    this.config = {
      paymentSystemsCore: new CashRegisterHelper(cloneDeep(this.$store.state.shopState.cashRegisters)),
      productsCore: new ProductsHelper(Currencies, cloneDeep(this.$store.state.shopState.products)),
      getNotices: (payload) => ShopService.getNotices('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, ...payload }),
      refundPayment: notice => {
        return ShopService.refundPayment('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, id: notice.id })
          .then(res => {
            const n = { ...notice }
            n.state = NoticeState.Refunded

            return n
          })
          .catch(errorNotification)
      },
      removeNotice: (id) => ShopService.removeNotice('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, id }),
      exportTables: {
        products: () => CSVExportService.ExportProducts('tg', this.$store.state.boardsState.activeBoard!.board),
        cashRegisters: () => CSVExportService.ExportCashRegistries('tg', this.$store.state.boardsState.activeBoard!.board),
        sales: () => CSVExportService.ExportSales('tg', this.$store.state.boardsState.activeBoard!.board),
      },
      getPreview: (image) => fileApi.getFilePreviewUrl(image, this.$store.state.boardsState.activeBoard!.board),
      getReports: () => new Promise(resolve => {
        ShopService.getProductsReport('tg', { board_key: this.$store.state.boardsState.activeBoard!.board })
          .then(({ items }) => resolve(items))
      })
    }
  }
}
